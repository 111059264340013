import * as React from 'react';
import { map as _map } from 'lodash';
import Image from '@lce/slice_v2/Elements/Image';
import Link from '@lce/slice_v2/Elements/Link';
import { IDefaultPageProps } from '@lce/intl-types/src/IDefaultPageProps';
import Container from '@lce/intl-ui/src/Layout/Container';

import Layout from '../../ui/ConnectedLayout';

export interface IInformationProps extends IDefaultPageProps {
  pageContext: {
    originalPath?: string;
    pageImage: string[];
    pdfFile: string;
    pdfLinkLabel: string;
  };
}

const Information: React.FC<IInformationProps> = ({ pageContext: { pageImage, pdfFile, pdfLinkLabel }, ...props }) => (
  <Layout {...props}>
    <Container  data-testid="InformationTemplate" sx={{ p: '4rem 16px' }}>
      { _map(pageImage, (url) => (
        <Image  
          alt="See PDF for information details link below" 
          key={ url } 
          src={url} 
          sx={{ 
            display: 'block',
            margin: '0 auto 1rem auto',
            width: '100%',
            height: 'auto',
            minHeight: '1px',
          }}
        />))
      }
      <Link href={pdfFile} sx={{ mb: '1rem' }} variant="primary">{pdfLinkLabel}</Link>
    </Container>
  </Layout>
);

export default Information;
